import React from 'react';
import parse from 'html-react-parser';
import { graphql } from 'gatsby';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Header from '../components/Header';
import LatestPosts from '../components/LatestPosts';
import InstaFeed from '../components/InstaFeed';
import Footer from '../components/Footer';


const NotFoundRoute = props => {
  const { wp, allWpCategory } = props.data;

  const description = "Die angefragte Resource wurde nicht gefunden."

  const categoryNames = allWpCategory.nodes
    .map(node => ({ name: node.name, uri: node.uri }))
    .filter(({ name }) => name !== 'Uncategorized');
 
  const NotFoundIcon = () => 
    <span className="fa-layers fa-fw" style={{display: 'inline-block', width: '10em', height: '10em', margin: '1em' }}>
      <FontAwesomeIcon icon="file" inverse style={{ height: '100%', width: '100%', color: '#33b9b7', transform: 'rotateZ(326deg)' }} />
      <FontAwesomeIcon icon="exclamation-triangle" transform="shrink-5" style={{color: '#fdd8c9', filter: 'drop-shadow(0 0 1em #e9db67)', height: '80%', width: '80%', left: 'calc(75px / 2)', transform: 'rotateZ(8deg)'}} />
    </span>;
  

  return (
    <Layout>
      <div>
        <Helmet>
          <html lang={wp.language} />
          <title>{`🔥 404 - ${wp.generalSettings.title}`}</title>
          <meta name="description" content={parse(description)} />
        </Helmet>
        <Header location="/404" />        
        <div className="container container--wide container--two-cols" style={{display: 'flex'}}>
          <main className="js-blog-posts post">            
            <article style={{textAlign: 'center' }}>
              <header>            
                <h1>404</h1>
                <h2>Da lief etwas schief...</h2>
              </header>
              <NotFoundIcon />
              <section>            
                <p>Die von Ihnen angeforderte Resource konnte nicht gefunden werden.</p>
              </section>
            </article>
          </main>
          <div className="container container--wide" style={{display: 'flex', justifyContent: 'space-around'}}>
            <LatestPosts />
          </div> 
        </div> 
        <InstaFeed />
        <Footer categories={categoryNames} />
      </div>
    </Layout>
  );
};

export default NotFoundRoute;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        adminUrl   
        rss
        robots
      }
    }
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }
    allWpCategory {
      nodes {
        name
      }
    }
    allWpPage {
      edges {
        node {
          uri
          title
        }
      }
    }
  }
`;
