import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

export default function LatestPosts() {
  const { allWpPost } = useStaticQuery(graphql`
    {
      allWpPost(limit: 12, sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
          }
        }
      }
    }
  `);

  const posts = allWpPost.edges;

  return (    
    <article id="latest-posts" className="post-preview" style={{boxShadow: 'none'}}>
      <header>        
        <h3><FontAwesomeIcon icon="align-left" style={{marginRight: '.5em'}} />Neuste Beiträge</h3>
        <hr noshade/>
      </header>
      <section>
        <ul>
        {posts.map(({node}) => (        
          <li key={node.uri}><Link to={node.uri} ariaLabel={node.title} dangerouslySetInnerHTML={{ __html: node.title }} /></li>
        ))}
        </ul>
      </section>
    </article>    
  );
}